import * as React from 'react';
import { useSelector } from 'react-redux';

import { trackLogoClick } from './tracking';
import { Logo } from '../../components/Logo';
import { IApplicationState } from '../../types/redux';
import { useApplicationContext } from '../../utils/applicationContext';

export function LogoContainer() {
  const {
    custom: { mainPageUrl },
    config,
  } = useApplicationContext();

  const noResize = useSelector((state: IApplicationState) => state.noResize);

  const customLogoUrl = config.get<string>('audience.cianNewYearLogoUrl');

  return <Logo noResize={noResize} url={mainPageUrl} src={customLogoUrl} onClick={trackLogoClick} />;
}
